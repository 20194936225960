import { useState } from 'react'
import { Modal } from 'components'
import { useEffect } from 'react'
import moment from 'moment/moment'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import NoRedirectPagination from 'components/no-redirect-pagination'
import { useRef } from 'react'
import InputDate from 'components/forms/input-date'
import { lang } from "config"
import { PlusIcon } from 'components/icons'
import useReferences from 'repositories/references'
import useTypes from 'repositories/type'

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Content = ({ data = [], accountNumber, selected, onSelect }) => {
    const [selectedReference, setSelectedReference] = useState(selected)

    const [page, setPage] = useState(1)

    const [search, setSearch] = useState(undefined)
    const [type, setType] = useState(undefined)
    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)

    const { data: typeSelection, isLoading: isLoadingType, mutate: mutateType } = useTypes({
        model: 'Bill'
    })

    const { data: referenceSelection, isLoading: isLoadingReference, mutate: mutateReference } = useReferences({
        page,
        status: 'waiting-bank-recon',
        paginate: 8,
        // automated: 'filter',
        search,
        account_number: accountNumber,
        type,
        from,
        to
    })

    // const prevSelectedReference = usePrevious(selectedReference)

    useEffect(() => {
        mutateReference()

        // if (prevSelectedReference !== selectedReference) {
        //     setPage(1)
        // }
    }, [selectedReference, page])

    useEffect(() => {
        setPage(1)
    }, [from, to, search])

    const handleChange = (value) => {
        if (selectedReference.find(row => row.transaction_number === value.transaction_number)) {
            setSelectedReference(selectedReference.filter((row) => row.transaction_number !== value.transaction_number))
        } else {
            setSelectedReference([...selectedReference, value])
        }
    }

    const handleSelect = () => {
        onSelect(selectedReference)
    }

    const clearSelection = () => {
        setSelectedReference([])
    }

    const resetFilter = (e) => {
        e.preventDefault()

        // if (Object.keys(selectedReference).length === 0) {
        setSearch('')
        // }

        setFrom(undefined)
        setTo(undefined)
    }

    return (
        <div className="mt-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:grid-cols-9">
                <div className="col-span-2">
                    <Label htmlFor="search" value={lang.search} />
                    <Input onChange={e => setSearch(e.target.value)} value={search} id="search" />
                </div>

                <div className="col-span-2">
                    <label for="type" className="block text-xs text-neutral-700">{lang.type}</label>
                    <select onChange={e => setType(e.target.value)} value={type} name="type" id="type" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={undefined} selected={type === undefined}></option>
                        <option value="petty-cash">Petty Cash</option>
                        {typeSelection?.map((row) => (
                            <option value={row.code}>{row.name}</option>
                        ))}
                    </select>
                </div>

                <div className="col-span-2">
                    <Label htmlFor="from" value={lang.from} />
                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-M-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="to" value={lang.to} />
                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-M-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                </div>

                {search && (selectedReference.length === 0 || from || to) && (
                    <div className="flex items-center text-xs text-neutral-700">
                        <button onClick={resetFilter} className="mt-5">{lang.reset}</button>
                    </div>
                )}
            </div>

            <div className="mt-4 overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_number}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.created_at}</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingReference && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {!isLoadingReference && referenceSelection?.data.length === 0 && !search && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {!isLoadingReference && referenceSelection?.data.length === 0 && search && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {!isLoadingReference && referenceSelection?.data.length !== 0 && referenceSelection?.data.map(row => (
                            <tr onClick={() => data.find((value) => value.transaction_number === row.transaction_number) ? null : handleChange(row)} className={`${data.find((value) => value.transaction_number === row.transaction_number) ? 'opacity-50' : 'hover:bg-neutral-50 cursor-pointer'} transition select-none`} key={row.transaction_number}>
                                <td className="pl-6">
                                    <div className="flex items-center h-5">
                                        <input disabled={data.find((value) => value.transaction_number === row.transaction_number)} onChange={() => handleChange(row)} checked={selectedReference.length !== 0 && selectedReference.find((selected) => selected.transaction_number == row.transaction_number)} id="pph" name="pph" type="checkbox" className="w-4 h-4 rounded-full border-neutral-300 text-neutral-800" />
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.transaction_number}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap capitalize">
                                    {row.data_type.replace('_', ' ')}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.transaction_type === 2 ? lang.internal_transfer : (row.transaction_type === 1 ? lang.expense : lang.income)}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.created_at).format('MMMM D, YYYY')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex items-start mt-8 space-x-2 text-xs">
                <button type="button" onClick={handleSelect} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.select}</span>
                </button>
                {selectedReference.length !== 0 && (
                    <button type="button" onClick={clearSelection} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                        <span>{lang.clear}</span>
                    </button>
                )}
                <NoRedirectPagination current={referenceSelection?.current_page} links={referenceSelection?.links} from={referenceSelection?.from} to={referenceSelection?.to} total={referenceSelection?.total} update={(value) => setPage(value)} />
            </div>
        </div>
    )
}

const SelectReference = ({ data, accountNumber, selected, onSelect, error }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSelect = (value) => {
        onSelect(value)
        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className={`${error ? 'border-red-200' : 'border-neutral-200'} inline-flex items-center px-4 py-3 space-x-2 transition border bg-neutral-50 rounded-xl active:hover:scale-90`}>
                <PlusIcon className="w-4 h-4" />
            </button>
            <Modal size="6xl" isOpen={isOpen} onClose={closeModal} title={`${lang.select} ${lang.references}`} content={<Content data={data} accountNumber={accountNumber} selected={selected} onSelect={handleSelect} />} />
        </>
    )
}

export default SelectReference